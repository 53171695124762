<template>
  <section>
    <b-form>
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input-default
                v-model="viewModel.Email"
                :label="$t('sectionPrescriber.personalData.email')"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="col-span-8">
          <div
            class="grid grid-cols-12 border-solid border-b border-gray-300 pb-2 gap-4"
          >
            <div class="col-span-12 xl:col-span-4">
              <input-default
                v-model="viewModel.endereco[1].CEP"
                :label="$t('sectionPrescriber.addressData.zipCode')"
                :hasIcon="true"
                disabled
              />
            </div>
            <div class="col-span-12 xl:col-span-6">
              <input-default
                v-model="viewModel.endereco[1].Rua"
                :label="$t('sectionPrescriber.addressData.homeAddress')"
                disabled
              />
            </div>
            <div class="col-span-12 xl:col-span-2">
              <input-default
                v-model="viewModel.endereco[1].Numero"
                :label="$t('sectionPrescriber.addressData.number')"
                disabled
              />
            </div>
          <div class="col-span-12 lg:col-span-4">
           <Dropdown 
                        :Items="dropdownItemsEstado"
                        v-model="viewModel.endereco[1].EstadoId"
                        disabled
                        :valueDefault="$t('sectionPrescriber.addressData.state')"
                        :label="$t('sectionPrescriber.addressData.state')"
                      @ItemSelectId="DropdownSelected($event,'estado')">
                </Dropdown>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <Dropdown 
                        :Items="dropdownItemsCidade"
                        v-model="viewModel.endereco[1].CidadeId"
                        disabled
                        :valueDefault="$t('sectionPrescriber.addressData.city')"
                        :label="$t('sectionPrescriber.addressData.city')"
                        @ItemSelectId="DropdownSelected($event,'cidade')">
                </Dropdown>
          </div>
          <div class="col-span-12 lg:col-span-4">
                <Dropdown
                        :Items="dropdownItemsBairro"
                        v-model="viewModel.endereco[1].BairroId"
                        disabled
                        :valueDefault="$t('sectionPrescriber.addressData.district')"
                        :label="$t('sectionPrescriber.addressData.district')"
                        @ItemSelectId="DropdownSelected($event,'bairro')">
                </Dropdown>
              </div>
              <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="viewModel.endereco[1].Complemento"
                max="20"
                :rules="{max:20 }"
                :label="$t('sectionProviders.addressData.complemento')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-2">
              <input-default
                v-model="viewModel.Telefone[2].DDD"
                :label="$t('sectionPrescriber.contactData.ddd1')"
                disabled
              />
            </div>
            <div class="col-span-12 xl:col-span-4">
              <input-default
                v-model="viewModel.Telefone[2].Numero"
                :label="$t('sectionPrescriber.contactData.homePhone')"
                disabled
              />
            </div>
            <div class="col-span-12 xl:col-span-6">
              <input-default
                v-model="viewModel.Proximidade"
                :label="$t('sectionPrescriber.contactData.proximity')"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <Dropdown 
                        :Items="dropdownItemsVisitador"
                        v-model="viewModel.VisitadorId"
                        :valueDefault="$t('sectionPrescriber.complementaryInfo.visitor')"
                        :label="$t('sectionPrescriber.complementaryInfo.visitor')"
                        disabled
                        @ItemSelectId="DropdownSelected($event,'cidade')">
              </Dropdown>
            </div>
            <div class="col-span-12">
              <input-default
                v-model="viewModel.ObservacaoVendas"
                :label="$t('sectionPrescriber.complementaryInfo.observationSale')"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="col-span-8">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <input-default
                v-model="viewModel.RegistroMapa"
                :label="$t('sectionPrescriber.complementaryInfo.mapRecord')"
                disabled
              />
            </div>
          </div>
          <div class="col-span-12">
            <div class="w-full flex items-center">
              <CheckboxDefault label="CEDH" v-model="viewModel.CEDH" disabled/>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script type="module">
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  name: "SupplementaryInformationPrescritor",
  components: {
    BForm,
    InputDefault,
    CheckboxDefault,
    Dropdown,
  },
  props: {
   viewModel: {
      type: Object,
      require:true,
    }
  },
  data() {
    return {
      dropdownItemsEstado: [],
      dropdownItemsVisitador: [],
      dropdownItemsBairro: [],
      dropdownItemsCidade: [],
    }
  },
  created () {
    this.ObterSeletorEstado();
    this.ObterSeletorVisitador();
  },
  methods: {
    ObterSeletorBairro (cidadeId) {
      this.$http({
        url: `/bairro/obter-seletor-bairro`,
        params: { idCidade: `${cidadeId}` },

        method: "GET"
      }).then((res) => {
        this.dropdownItemsBairro = res.data;
      })
    },
    ObterSeletorEstado () {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET"
      }).then((res) => {
        this.dropdownItemsEstado = res.data;
      })
    },
    ObterSeletorCidade (estadoId) {
      this.$http({
        url: `/cidade/obter-seletor-cidades-por-estado/${estadoId}`,
        method: "GET"
      }).then((res) => {
        this.dropdownItemsCidade = res.data;
      })
    },
    ObterSeletorVisitador () {
      this.$http({
        url: `/visitador/obter-seletor-visitador`,
        method: "GET"
      }).then((res) => {
        this.dropdownItemsVisitador = res.data;
      })
    },
    DropdownSelected (item, tipo) {
       if (tipo == 'estado') {
        this.viewModel.endereco.EstadoId = item;
        this.ObterSeletorCidade(item);
      } else if (tipo == 'cidade') {
        this.viewModel.endereco.CidadeId = item;
        this.ObterSeletorBairro(item);
      } else if (tipo == 'bairro') {
        this.viewModel.endereco.BairroId = item;
      }
    }
  },
};
</script>
