<template>
  <section class="w-full">
    <header-register :showButtonView="(previewButtonIsVisible = false)"
      >{{ $t("sectionPrescriber.titlePrescritor") }}
    </header-register>
    <main class="w-full">
      <ManagePrescritorView />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManagePrescritorView from "@/components/prescritor/components/view/ManagePrescritorView.vue";

export default {
  name: "PrescritorEdit",
  components: {
    HeaderRegister,
    ManagePrescritorView,
  },
};
</script>
