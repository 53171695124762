<template>
  <b-form>
    <div class="grid grid-cols-1 md:grid-cols-12">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 lg:col-span-12">
            <input-required
              v-model="viewModel.Name"
              :label="$t('sectionPrescriber.personalData.name')"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="col-span-8 mb-3">
        <div class="flex flex-col items-start gap-2">
          <span class="text__color font-bold">Tipo CR *</span>
          <div class="flex">
            <div class="flex items-center">
              <radioButton
                :options="options"
                v-model="viewModel.TipoCr"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-2">
            <input-required
              v-model="viewModel.UfCr"
              :label="$t('sectionPrescriber.personalData.ufCr')"
              :rules="{ required: true,  max: 2,min: 2 }"
              mask="XX"
              placeholder="UF"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-required
              v-model="viewModel.NumeroCR"
              :label="$t('sectionPrescriber.personalData.crNumber')"
              :rules="{ required: true,  max: 10,min: 2 }"
              mask="XXXXXXXXXX"
              placeholder="Numero"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="viewModel.DataNascimento"
              :label="$t('sectionPrescriber.personalData.birthDate')"
              mask="##/##/####"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="viewModel.CpfCnpj"
              :label="$t('sectionPrescriber.personalData.document')"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              disabled
            />
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
      <div class="col-span-8 border-solid border-b border-gray-300">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 xl:col-span-3">
            <input-default
              v-model="viewModel.endereco[0].CEP"
              :label="$t('sectionPrescriber.addressData.zipCode')"
              mask="#####-###"
              :hasIcon="true"
              disabled
            />
          </div>
          <div class="col-span-12 xl:col-span-7">
            <input-default
              v-model="viewModel.endereco[0].Rua"
              :label="$t('sectionPrescriber.addressData.road')"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-2">
            <input-default
              v-model="viewModel.endereco[0].Numero"
              :label="$t('sectionPrescriber.addressData.number')"
              placeholder="Nº"
              disabled
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
           <Dropdown 
                        :Items="dropdownItemsEstado"
                        v-model="viewModel.endereco[0].EstadoId"
                        disabled
                        :valueDefault="$t('sectionPrescriber.addressData.state')"
                        :label="$t('sectionPrescriber.addressData.state')"
                      @ItemSelectId="DropdownSelected($event,'estado')">
                </Dropdown>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <Dropdown 
                        :Items="dropdownItemsCidade"
                        v-model="viewModel.endereco[0].CidadeId"
                        :valueDefault="$t('sectionPrescriber.addressData.city')"
                        :label="$t('sectionPrescriber.addressData.city')"
                        disabled
                        @ItemSelectId="DropdownSelected($event,'cidade')">
                </Dropdown>
          </div>
          <div class="col-span-12 lg:col-span-4">
                <Dropdown
                        :Items="dropdownItemsBairro"
                        v-model="viewModel.endereco[0].BairroId"
                        :valueDefault="$t('sectionPrescriber.addressData.district')"
                        :label="$t('sectionPrescriber.addressData.district')"
              disabled
                        @ItemSelectId="DropdownSelected($event,'bairro')">
                </Dropdown>
              </div>
              <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="viewModel.endereco[0].Complemento"
                max="20"
                :rules="{max:20 }"
                :label="$t('sectionProviders.addressData.complemento')"
              />
            </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-8 border-solid border-b border-gray-300">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-2">
            <input-default
              v-model="viewModel.Telefone[0].DDD"
              :label="$t('sectionPrescriber.contactData.ddd1')"
              disabled
              placeholder="(99)"
              mask="(##)"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="viewModel.Telefone[0].Numero"
              :label="$t('sectionPrescriber.contactData.phone')"
              placeholder="9999-9999"
              disabled
              mask="####-####"
            />
          </div>
          <div class="col-span-12 lg:col-span-2">
            <input-default
              v-model="viewModel.Telefone[1].DDD"
              :label="$t('sectionPrescriber.contactData.ddd2')"
              placeholder="(99)"
              disabled
              mask="(##)"
            />
          </div>
          <div class="col-span-12 lg:col-span-4">
            <input-default
              v-model="viewModel.Telefone[1].Numero"
              :label="$t('sectionPrescriber.contactData.cellPhone')"
              placeholder="9 9999-9999"
              disabled
              mask="# ####-####"
            />
          </div>
        </div>
      </div>
      <div class="col-span-8">
        <input-default
          v-model="viewModel.NmSecretaria"
              disabled
          :label="$t('sectionPrescriber.complementaryInfo.secretary')"
        />
      </div>
      <div class="col-span-8">
        <input-default
          v-model="viewModel.NomeRotulo"
              disabled
          :label="$t('sectionPrescriber.complementaryInfo.labelName')"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-8">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12">
            <div class="flex items-center justify-between">
              <span class="text__color text-xl font-bold">{{$t('sectionPrescriber.especialidade')}}</span>
            </div>
          </div>
          <div class="col-span-12 mb-3">
            <ul class="list-inline d-inline-block mb-2 text-sm text-white rounded">
            <li v-for="especialidade in viewModel.Especialidade" :key="especialidade" class="list-inline-item">
              <b-form-tag
                :title="especialidade.especialidade"
                variant="info"
              >
              {{especialidade.especialidade}}
              </b-form-tag>
            </li>
          </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex items-center justify-start">
      <CheckboxDefault label="Ativo" v-model="viewModel.Ativo" disabled/>
    </div>
  </b-form>
</template>

<script>
import { required } from "@validations";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import radioButton from "@core/components/commons/radioButton/radioButton.vue";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    CheckboxDefault,
    radioButton,
    Dropdown,
  },
  props: {
    required,
    viewModel: {
      type: Object,
      require:true,
    }
  },
  data() {
    return {
      seletorEspecialidades: [],
      dropdownItemsEstado:[],
      dropdownItemsBairro:[],
      dropdownItemsCidade:[],
      options: [
        {text:"CRM" , value:0, },
        {text:"CRMV" , value:1, },
        { text:"CRO" ,value:2, },
        {text:"OUTRO" , value:3, },
      ],
    };
  },
  created () {
    this.ObterSeletorEstado();

   },
  methods: {
    ObterSeletorBairro(cidadeId) {
      this.$http({
        url: `/bairro/obter-seletor-bairro`,
        params: { idCidade: `${cidadeId}` },

        method: "GET"
      }).then((res) => {
          this.dropdownItemsBairro = res.data;
        })
    },
    ObterSeletorEstado() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsEstado = res.data;
        })
    },
    ObterSeletorCidade(estadoId) {
      this.$http({
        url: `/cidade/obter-seletor-cidades-por-estado/${estadoId}`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsCidade = res.data;
        })
    },
    DropdownSelected (item, tipo) {
      if(tipo == 'estado') {
        this.viewModel.endereco.EstadoId = item;
        this.ObterSeletorCidade(item);
      } else if (tipo == 'cidade') {
        this.viewModel.endereco.CidadeId = item;
        this.ObterSeletorBairro(item);
      }else if (tipo == 'bairro') {
        this.viewModel.endereco.BairroId = item;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text__color {
  color: #cf0209;
}
    ul li{
        margin-bottom: 0.5rem;
      .badge {
        background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
      }
    span{
        width: 6rem;
        height: 1.6rem;
        text-align: start;
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
        font-family: inherit;
        color: #fff;
        font-weight: 500;
      }
    }
</style>
