<template>
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralPrescritorInformation
            class="mt-4"
            :viewModel="viewModel"
          />
        </b-tab>
        <b-tab title="Complemento">
          <SupplementaryInformationPrescritor
            class="mt-4"
            :viewModel="viewModel"
          />
        </b-tab>
      </b-tabs>
    </section>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
        buttonText="Voltar"
        :onClick="redirectUrl"
      />
    </footer>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralPrescritorInformation from "./GeneralPrescritorInformation";
import SupplementaryInformationPrescritor from "./SupplementaryInformationPrescritor";
import Button from "@core/components/commons/button/Button";

export default {
  name: "ManageSuppliersView",
  components: {
    BTabs,
    BTab,
    GeneralPrescritorInformation,
    SupplementaryInformationPrescritor,
    Button,
  },
  data() {
    return {
      viewModel: {
        Name: null,
        TipoCr: null,
        CpfCnpj: null,
        DataNascimento: null,
        Ativo: null,
        NumeroCR: null,
        UfCr:null,
        Secretaria: null,
        NomeRotulo: null,
        Email: null,
        VisitadorId: null,
        ObservacaoVendas: null,
        RegistroMapa: null,
        Proximidade: null,
        CEDH: false,
        endereco: [
          {	
          CEP: null,
          Endereco: null,
          BairroId: null,
          CidadeId: null,
          EstadoId: null,
          Numero: null,
          Complemento:null,
          IsResidencial: false,
          },
        {
          CEP: null,
          Endereco: null,
          BairroId: null,
          CidadeId: null,
          EstadoId: null,
          Complemento:null,
          Numero: null,
          IsResidencial: false,
        }],
        Telefone: [
          {
            DDD: null,
            Numero: null,
            IsResidencial: false,
          },
          {
            DDD: null,
            Numero: null,
            IsResidencial: false
          },
          {
            DDD: null,
            Numero: null,
            IsResidencial: true
          }
        ],
        Especialidade: [],
      }
    };
  },
  created () { 
    this.RecuperarUser(this.$router.currentRoute.params.id);
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/prescritor");
    },
    RecuperarUser (id)
    {
      this.$http({
        url: `/prescritor/obter/${id}`,
        method: "Get"
      }).then((response) => {
            this.viewModel.Name = response.data.nome,
            this.viewModel.TipoCr = response.data.tipoCr,
            this.viewModel.CpfCnpj= response.data.cpfCnpj,
            this.viewModel.DataNascimento= this.dataFormatada(response.data.dtAniversario),
            this.viewModel.Ativo = response.data.snAtivo,
            this.viewModel.NumeroCR= response.data.nrCr,
            this.viewModel.UfCr= response.data.siglaUFCR,
            this.viewModel.NmSecretaria= response.data.nmSecretaria,
            this.viewModel.NomeRotulo= response.data.nomeRotulo,
            this.viewModel.Especialidade = response.data.prescritorEspecialidade,
            this.viewModel.Email= response.data.email,
            this.viewModel.VisitadorId= response.data.visitadorId,
            this.viewModel.ObservacaoVendas= response.data.observacaoVenda,
            this.viewModel.RegistroMapa= response.data.numRegMapa,
            this.viewModel.Proximidade= response.data.proximidade,
            this.viewModel.CEDH= response.data.cedh
            var telefone = response.data.telefones?.filter((item) => item.numero?.length <= 8 && !item.isResidencial);
            this.viewModel.Telefone[0] = {
                DDD: telefone[0]?.ddd || null,
                Numero: telefone[0]?.numero || null,
                IsResidencial: telefone[0]?.isResidencial || null
            }

            var celular = response.data.telefones?.filter((item) => item.numero?.length >= 9 && !item.isResidencial);
            this.viewModel.Telefone[1] = {
                DDD: celular[0]?.ddd || null,
                Numero: celular[0]?.numero || null,
                IsResidencial: celular[0]?.isResidencial || null
           }
          var telefoneResidencial = response.data.telefones?.filter((item) => item.numero?.length >= 8 && item.isResidencial);
            this.viewModel.Telefone[2] = {
                DDD: telefoneResidencial[0]?.ddd || null,
                Numero: telefoneResidencial[0]?.numero || null,
                IsResidencial: telefoneResidencial[0]?.isResidencial || null
        }
          //Endereço
            var endereco = response.data.enderecos?.filter((item) => !item.isResidencial);
            this.viewModel.endereco[0] = {
              CEP: endereco[0].cep || null,
                Rua: endereco[0].rua || null,
                BairroId: endereco[0].bairroId ||  null,
                CidadeId: endereco[0].cidadeId ||  null,
                EstadoId: endereco[0].estadoId || null,
                Complemento: endereco[0].complemento,
                Numero: endereco[0].numero ||  null,
                IsResidencial: endereco[0].isResidencial,
            }
        var enderecoResidencial = response.data.enderecos?.filter((item) => item.isResidencial);
            this.viewModel.endereco[1] = {
                CEP: enderecoResidencial[0].cep || null,
                Rua: enderecoResidencial[0].rua ||  null,
                BairroId: enderecoResidencial[0].bairroId ||  null,
                CidadeId: enderecoResidencial[0].cidadeId ||  null,
                EstadoId: enderecoResidencial[0].estadoId ||  null,
                Complemento: enderecoResidencial[0].complemento,
                Numero: enderecoResidencial[0].numero ||  null,
                IsResidencial: enderecoResidencial[0].isResidencial,
        }
      })
    },
    dataFormatada (aniversario) {
      if (aniversario == null || aniversario == "")
        return null;

      var data = aniversario.split("-")
      return `${data[2].substr(0,2)}/${data[1]}/${data[0]}`;
    }
  },
};
</script>

<style></style>
